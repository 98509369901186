import { createStyles } from "../../emotion-styles";
//import { blue, grey, lightBlue } from "@mui/material/colors";

export const styles = createStyles({
  container: {
    //   backgroundColor: blue[600],
    padding: "2em",
    //  margin: "45px",
  },
  fab: {
    top: 60,
    left: 16,
    position: "absolute",
  },
  fabEstimate: {
    top: 60,
    right: 16,
    position: "absolute",
  },
  text: {
    // color: lightBlue[400],
    fontSize: "2.0rem",
    //  color: "text.primary",
    marginTop: 8,
    textAlign: "center",
  },
  byAppointment: {
    // color: lightBlue[400],
    fontSize: "1rem",
    //  color: "text.primary",
    marginTop: 8,
    textAlign: "center",
  },
  mainParagraph: {
    fontFamily: "SFRegular",
    fontSize: "1rem",
  },
  banner1: {
    fontFamily: "SFRegularItalic",
    fontSize: "1.25rem",
    textAlign: "center",
  },
  banner2: {
    fontFamily: "SFBoldItalic",
    fontSize: "1.25rem",
    textAlign: "center",
    marginTop: 8,
    marginBottom: 8,
    color: "tomato",
  },
  slideshowContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  containerStyles: {
    width: "600px",
    height: "320px",
    margin: "0 auto",
  },
});
