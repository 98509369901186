/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import { Typography, Fab } from "@mui/material";
import DirectionsIcon from "@mui/icons-material/Directions";
import EstimateIcon from "@mui/icons-material/DynamicForm";
import { styles } from "./styles";
import React, { useEffect } from "react";
import "react-slideshow-image/dist/styles.css";
import ImageSlider from "../../imageSlider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

const slideImages = [
  {
    url: "images/1.png",
    title: "Slide 1",
  },
  {
    url: "images/2.png",
    title: "Slide 2",
  },
  {
    url: "images/3.png",
    title: "Slide 3",
  },
  {
    url: "images/4.png",
    title: "Slide 4",
  },
];
interface Props {}

export const Home: React.FC<Props> = () => {
  const navigate = useNavigate();
  const smMatch = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  return (
    <>
      <Link to="/directions">
        <Fab css={styles.fab} color="secondary" size="small" variant="extended">
          <DirectionsIcon />
          directions
        </Fab>
      </Link>
      <Link to="/estimate">
        <Fab
          css={styles.fabEstimate}
          color="secondary"
          variant="extended"
          size="small"
        >
          <EstimateIcon />
          Estimate
        </Fab>
      </Link>
      <div css={styles.container}>
        <Typography color="primary" css={styles.text}>
          Welcome
        </Typography>
        <Typography color="primary" css={styles.byAppointment}>
          We service by appointment only <br />
          call <a href="tel:831-460-1200">831-460-1200</a>
          <br />
          or click on the estimate button above
        </Typography>
        <div
          css={styles.containerStyles}
          style={smMatch ? { width: "100%", height: 190 } : {}}
        >
          <ImageSlider slides={slideImages} />
        </div>
        <Typography css={styles.banner1}>
          Santa Cruz Auto Glass Specialists
        </Typography>
        <Typography css={styles.banner2}>
          if it's glass and it's part of your car, we can help.
        </Typography>
        <Typography css={styles.mainParagraph}>
          At Acumen Auto Glass, we proudly service all makes and models,
          delivering reliable, high-quality automotive glass solutions. Our
          mission is to exceed industry standards while prioritizing your
          satisfaction. Backed by highly trained technicians, we ensure the
          structural integrity of your vehicle with expert installation that
          meets the rigorous standards of the National Glass Association’s
          technical program. Committed to sustainability, we follow
          environmentally responsible practices. To make your experience
          seamless, we also collaborate with insurance companies to assist with
          claim setup.
        </Typography>
      </div>
    </>
  );
};
