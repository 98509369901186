import { createStyles } from "../../emotion-styles";
//import { blue, grey, lightBlue } from "@mui/material/colors";

export const styles = createStyles({
  hours: {},
  hourheadng: {
    fontWeight: "bold",
    fontSize: "1.25rem",
  },
  byApp: {
    fontStyle: "italic",
    fontWeight: "bold",
    color: "#b87f6e",
    fontSize: "1.25rem",
  },
  container: {
    //   backgroundColor: blue[600],
    padding: "2em",
    //  margin: "45px",
  },

  text: {
    // color: lightBlue[400],
    fontSize: "2.0rem",
    //  color: "text.primary",
    textAlign: "center",
  },
  address: {
    marginBottom: 8,
  },
  city: { marginBottom: 8 },
  phone: { marginBottom: 8 },

  imgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "18px auto 0",
  },
  img: {
    maxWidth: "70%",
  },
});
