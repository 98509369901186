import { createStyles } from "../../emotion-styles";
//import { blue, grey, lightBlue } from "@mui/material/colors";

export const styles = createStyles({
  container: {},
  imgcontainer: {
    display: "flex",
    justifyCntent: "center" /* Horizontal centering */,
    alignItems: "center" /* Vertical centering */,
    //  height: "100vh" /* Ensure container is tall enough */,
    margin: "0 auto 12px",
  },
  text: {
    // color: lightBlue[400],
    fontSize: "2.0rem",
    //  color: "text.primary",
    textAlign: "center",
  },
  mainParagraph: {
    fontFamily: "SFRegular",
    fontSize: "1rem",
    marginBottom: 12,
    margin: "0 12px 12px 12px",
  },
  banner1: {
    fontFamily: "SFRegularItalic",
    fontSize: "1.25rem",
    textAlign: "center",
  },
  banner2: {
    fontFamily: "SFBoldItalic",
    fontSize: ".75rem",
    textAlign: "center",
    marginTop: 8,
    marginBottom: 8,
  },
  slideshowContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  image: {
    height: "320px",
    margin: "0 auto",
  },
});
