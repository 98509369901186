/** @jsxImportSource @emotion/react */
import { Typography, Paper } from "@mui/material";
import { styles } from "./styles";
import React, { useEffect } from "react";
import "react-slideshow-image/dist/styles.css";
import ImageSlider from "../../imageSlider";
import useMediaQuery from "@mui/material/useMediaQuery";
const slideImages = [
  {
    url: "images/1.png",
    title: "Slide 1",
  },
  {
    url: "images/2.png",
    title: "Slide 2",
  },
  {
    url: "images/3.png",
    title: "Slide 3",
  },
  {
    url: "images/4.png",
    title: "Slide 4",
  },
];
interface Props {}

export const AboutUs: React.FC<Props> = () => {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  return (
    <div css={styles.container}>
      <Typography
        color="primary"
        css={styles.text}
        style={{ marginTop: 8, marginBottom: 12 }}
      >
        About Acumen
      </Typography>
      <div css={styles.imgcontainer}>
        <img src="images/aboutus/1.png" css={styles.image}></img>
      </div>

      {/*       <Typography css={styles.banner1}>
        Santa Cruz Auto Glass Specialists
      </Typography>
      <Typography css={styles.banner2}>
        if it's glass and it's part of your car, we can help.
      </Typography> */}
      <Typography css={styles.mainParagraph}>
        Acumen Auto Glass is your trusted partner for all auto glass needs.
        Whether you drive an older car, a luxury vehicle, an RV, a bus, or a
        commercial truck, we’ve got you covered. Offering both in-shop service
        at our convenient Campbell and Santa Cruz locations, as well as
        efficient mobile service with computer-dispatched trucks, we prioritize
        flexibility and convenience.
      </Typography>
      <Typography css={styles.mainParagraph}>
        We stand by our work with a Full Lifetime Warranty on all automotive
        glass replacements, covering leaks, workmanship, and molding issues.
        Windshield repairs also include a Lifetime Guarantee against cracks
        spreading from the repair.
      </Typography>
      <Typography css={styles.mainParagraph}>
        At Acumen Auto Glass, we are committed to delivering an exceptional
        customer experience. From your first contact to the moment we send you
        on your way, your satisfaction is our priority. Our technicians are the
        best-trained in the Bay Area, and we’re proud to uphold environmentally
        safe practices to care for our planet while serving you.
      </Typography>
    </div>
  );
};
