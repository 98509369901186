/** @jsxImportSource @emotion/react */
import { Typography, Paper } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styles } from "./styles";

interface Props {}
export const Services: React.FC<Props> = () => {
  return (
    <div css={styles.container}>
      <Typography color="primary" css={styles.text}>
        Services
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography css={styles.summary}>
            Automotive Glass Replacement
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src="images/services/replace.png" css={styles.image}></img>
          <Typography style={{ marginBottom: 12 }}>
            Acumen Auto Glass provides comprehensive auto glass replacement for
            all vehicle makes and models. Our certified technicians combine
            expertise, precision, and industry best practices to ensure
            reliable, long-lasting installations that withstand harsh driving
            conditions.
          </Typography>
          <Typography>
            The evolution of auto glass installation techniques spans decades.
            In the early years, installations relied on rubber gaskets and basic
            sealants. The 1970s saw butyl become the industry standard, using a
            bead of gum-like sealant instead of gaskets. However, butyl's
            vulnerability to water leaks led to its decline. By the 1980s, new
            vehicle designs and Department of Transportation standards drove the
            industry toward modern urethane adhesives, which remain the gold
            standard today.
          </Typography>
          <Typography style={{ marginTop: 12 }}>
            Our commitment to quality means using only premium materials and
            following manufacturer specifications for every installation. We
            understand that proper auto glass installation is crucial for both
            safety and structural integrity.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography css={styles.summary}>Windshield Repair</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src="images/services/repair.jpg" css={styles.image}></img>s
          <Typography>
            Don't delay repairing windshield rock chips – quick action can save
            you hundreds. These small fractures, typically caused by flying
            rocks and road debris, can rapidly worsen due to daily temperature
            changes and structural stress from normal driving. Our professional
            repair service includes: Four-step repair process Lifetime warranty
            against crack spreading Premium glass resin and specialized
            equipment Expert technician craftsmanship Schedule today for a fast,
            reliable repair that protects both your windshield and your wallet.
            With our proven process and quality guarantee, you can trust us to
            fix your windshield right the first time. The revised version
            maintains all key information while being more direct and easier to
            read. Would you like any specific elements emphasized or adjusted?
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography css={styles.summary}>
            Window Regulator Replacement
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src="images/services/door.jpg" css={styles.image}></img>

          <Typography>
            Acumen Auto Glass offers a full range of auto glass replacement
            services for all makes and all models. Automotive glass replacement
            is not the easiest task to perform on vehicles. There is actually a
            great amount of knowledge, experience, and integrity that is needed
            in order to successfully complete a glass replacement in an
            automobile. There is no room for error with replacements. Precise
            workmanship and attention to detail is a must in order to allow our
            replacements to hold up to the harsh environments that cars endure.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography css={styles.summary}>
            Automotive Body Glass Services
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src="images/services/body.jpg" css={styles.image}></img>

          <Typography>
            Acumen Auto Glass offers a full range of auto glass replacement
            services for all makes and all models. Automotive glass replacement
            is not the easiest task to perform on vehicles. There is actually a
            great amount of knowledge, experience, and integrity that is needed
            in order to successfully complete a glass replacement in an
            automobile. There is no room for error with replacements. Precise
            workmanship and attention to detail is a must in order to allow our
            replacements to hold up to the harsh environments that cars endure.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography css={styles.summary}>Fleet Services</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <img src="images/services/fleet.png" css={styles.image}></img>

          <Typography>
            Acumen Auto Glass offers a full range of auto glass replacement
            services for all makes and all models. Automotive glass replacement
            is not the easiest task to perform on vehicles. There is actually a
            great amount of knowledge, experience, and integrity that is needed
            in order to successfully complete a glass replacement in an
            automobile. There is no room for error with replacements. Precise
            workmanship and attention to detail is a must in order to allow our
            replacements to hold up to the harsh environments that cars endure.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
